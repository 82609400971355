
import { defineComponent } from 'vue';
import TeamLabel from '@/components/TeamLabel.vue';
import { store } from '@/store/store';
import { TeamInterface } from '@/services/teams';
import { TeamStats } from '@/services/stats';

export default defineComponent({
    name: 'RankingTable',
    components: { TeamLabel },
    props: {
        category: {
            type: String,
            required: true
        }
    },
    computed: {
        ranking(): TeamStats[] {
            return store.state.seasonRanking[this.category] ?? [];
        }
    },
    methods: {
        teamInfo(teamName: string): TeamInterface {
            return store.getters.teamInfo(teamName);
        },
        statsInfo(stats: TeamStats): string {
            return `PP: ${stats.pf} | PC ${stats.pa} | +/- ${stats.diff}`;
        }
    }
});
