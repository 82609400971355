
import { defineComponent } from 'vue';
import RankingTable from '@/components/RankingTable.vue';
import { store } from '@/store/store';
import { decodeTeamDivision, divisionLongDisplayName } from '@/utils';
import { ACTION } from '@/store/actions';
import { STATE } from '@/constants';

export default defineComponent({
    name: 'Ranking',
    components: { RankingTable },
    data() {
        return {
            generalRanking: false,
            collapsed: {} as {[section: string]: boolean}
        }
    },
    computed: {
        categories(): string[] {
            return this.generalRanking? this.partDiv: this.fullDiv;
        },
        fullDiv(): string[] {
            return store.getters.sortedSeasonCategories;
        },
        partDiv(): string[] {
            return store.getters.sortedDivisions;
        },
        isLoading(): boolean {
            return store.state.seasonRankingRequestStatus.status === STATE.LOADING;
        },
        isReady(): boolean {
            return store.state.seasonRankingRequestStatus.status === STATE.DONE;
        }
    },
    methods: {
        isCollapsed(d: string): boolean {
            return this.collapsed[d] ?? false;
        },
        toggleGroupByTeam() {
            this.generalRanking = !this.generalRanking;
        },
        toggleTable(d: string){
            this.collapsed[d] = !this.isCollapsed(d);
        },
        divisionLongDisplayName(category: string): string {
            const {division, pool} = decodeTeamDivision(category);
            return divisionLongDisplayName(division, pool)
        }
    },
    mounted() {
        if(store.state.seasonRankingRequestStatus.status === STATE.STALE) {
            store.dispatch(ACTION.FETCH_SEASON_RANKINGS, undefined);
        }
    }
});
